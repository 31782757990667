
#wrapper {
  /* background-color: #f5f5f5 !important; */
  /* padding-top: 72px; */
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Circular Std Bold' !important;
}

button, input, optgroup, select, textarea, div, span {
  font-family: 'Circular Std Medium' !important;
}

.sidebar-courses {
  padding: 7px 0 0;
    /* margin-bottom: 15px; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.courses-info ul li {
  display: flex;
  align-items: center; /* Align items vertically */
  list-style: none;
  border-bottom: 0.4px solid #2c3351;
  margin-bottom: 6px;
  padding: 0 20px 6px 18px;
}

.courses-info ul li:hover .course-info-icon{
  animation: clock-alarm 2s ease;
}

.courses-info ul li .course-info-icon {
  display: inline-block;
  padding-right: 5px;
  color: #657FF1;
}

.courses-info ul li .course-info-label {
  min-width: 130px;
}

.courses-info ul li .course-info-text {
  flex: 1; /* Allow the text to take up remaining space */
  word-wrap: break-word; /* or overflow-wrap: break-word; */
  overflow-wrap: anywhere;
  text-align: right;
  margin-left: auto;
  padding-left: 10px;
}

.courses-price-single-box {
  text-align: center;
}

.courses-price-content {
  margin-top: 8px;
}

.courses-price-content .price {
  font-size: 24px;
  /* color: #455fe3; */
  /* text-shadow: 2px 0 #455fe3, -2px 0 #455fe3, 0 2px #455fe3, 0 -2px #455fe3, 1px 1px #455fe3, -1px -1px #455fe3, 1px -1px #455fe3, -1px 1px #455fe3; */
}

.courses-price-content h4 {
  font-size: 20px;
}

.nest-btn {
  display: inline-block;
  outline: none !important;
  text-decoration: none;
  border-radius: 8px;
  font-size: 21px;
  padding: 7px 25px 12px;
  transition: 500ms;
  overflow: hidden;
  z-index: 2;
  position: relative;
  transition-delay: calc((var(--n) - 1)* 0.1s);
  -webkit-transition-delay: calc((var(--n) - 1)* 0.1s);
  -moz-transition-delay: calc((var(--n) - 1)* 0.1s);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
  border: 3px solid #657FF1;
}

.nest-btn__shape {
  display: inline-block;
  position: absolute;
  width: 25%;
  height: 100%;
  background: #657FF1;
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--b) - 1)* 25%);
  transition: 0.5s;
  transition-delay: calc((var(--b) - 1)* 0.1s);
  z-index: 1;
}

.nest-btn__shape:nth-child(1) {
  --b: 1;
}

.nest-btn__shape:nth-child(2) {
  --b: 2;
}

.nest-btn__shape:nth-child(3) {
  --b: 3;
}

.nest-btn__shape:nth-child(4) {
  --b: 4;
}

.nest-btn__text {
  position: relative;
  z-index: 2;
  top: 4px;
}

.nest-btn:hover {
  color: #ffff;
}

.nest-btn:hover .nest-btn__shape {
  transform: translateY(0) scale(2);
  -moz-transform: translateY(0) scale(2);
  -moz-transform: translateY(0) scale(2);
}

.nest-btn-sm {
  display: inline-block;
  outline: none !important;
  text-decoration: none;
  color: #ffffff;
  border-radius: 35px;
  background: linear-gradient(90deg, #657FF1 33.04%, #657FF1 246.73%);
  font-size: 14px;
  padding: 0px 12px 8px;
  transition: 500ms;
  overflow: hidden;
  z-index: 2;
  position: relative;
  transition-delay: calc((var(--n) - 1)* 0.1s);
  -webkit-transition-delay: calc((var(--n) - 1)* 0.1s);
  -moz-transition-delay: calc((var(--n) - 1)* 0.1s);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.nest-btn-sm:hover {
  color: #ffff;
}

.nest-btn-sm:hover .nest-btn__shape {
  transform: translateY(0) scale(2);
  -moz-transform: translateY(0) scale(2);
  -moz-transform: translateY(0) scale(2);
}

.video-container {
  /* max-width: 750px; */
  width: 100%;
  border-radius: 4px;
  /* margin: 0 auto; */
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4); */
}

.video-container .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  /* max-height: 300px; */
  /* border-radius: 4px 4px 0px 0px; */
}

.video-container-view {
  /* max-width: 750px; */
  width: 100%;
  /* border-radius: 4px; */
  /* margin: 0 auto; */
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4); */
  background: linear-gradient(166.17deg, rgba(39, 64, 166, 0.0201513) -80.38%, rgba(8, 10, 12, 0.1) 42.01%, rgba(19, 40, 137, 0.1) 148.61%, rgba(0, 0, 0, 0.1) 148.61%);
}

.video-container-view .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container-view video {
  aspect-ratio: 16 / 9;
  border-radius: 13px;

  /* max-height: 300px; */
  /* border-radius: 4px 4px 0px 0px; */
}

.play-button-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}
.play-button-wrapper #circle-play-b {
  cursor: pointer;
  pointer-events: auto;
  background-color: #27293bd6;
  text-transform: uppercase;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.949091px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 4px 7px;
  border-radius: 9px;
  box-shadow: 0px 4px 8px rgba(25, 28, 50, 0.321788);
}

.play-button-wrapper #circle-play-b svg {
  /* font-size: 80px;
  color: #fff; */
  width: 70px;
  height: 70px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}

.class-detail-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
}

.small-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: solid 1px;
  border-color: #455fe3;
  border-radius: 9999px;
  gap: 8px;
  padding: 8px 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #455fe3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
}

.small-box i {
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  vertical-align: middle;
  font-size: 17px;
}

.alarm {
  animation: clock-alarm 5s ease infinite;
}

@keyframes clock-alarm {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

.small-box span {
  font-size: 0.75rem;
  line-height: 133%;
  color: #0B0B0B;
  text-transform: capitalize;
  white-space: nowrap;
}

.teacher-details-box{
  background: #f9f7ff;
  padding: 10px 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  /* max-width: 500px; */
}

.box-style {
  /* background-color: #f9f7ff; */
  padding: .5rem;
  margin-bottom: .5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.b-l-s {
  border: 5px #657FF1;
  border-left-style: solid;
}

.day_left_style1{
  background: #657FF1;
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  box-shadow: #657FF1 0px 2px 1px;
}

.class-detail-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
}

.small-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: solid 1px;
  border-color: #657FF1;
  border-radius: 5px;
  gap: 8px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #657FF1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
}

.small-box i {
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  vertical-align: middle;
  font-size: 17px;
}

.small-box span {
  font-size: 0.75rem;
  line-height: 133%;
  color: #0B0B0B;
  text-transform: capitalize;
  white-space: nowrap;
}

.i-blue-theme{
  color: #657FF1;
}

.i-whatsapp-theme{
  color: #25D366;
}
/* -------------------------------- */

.ribbon-box {
  position:relative;
}

.ribbon-style {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 0px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: orange;
  color: #fff;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  z-index: 2;
}

.btn-outline-theme {
display: inline-block;
padding: 0.10rem .50rem;
color: #657FF1;
background-color: #ebeeff;
border: solid 1px #657FF1;
text-transform: uppercase;
transition: all 0.3s;
position: relative;
overflow: hidden;
z-index: 1;
border-radius: 5px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.btn-outline-theme:after {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
z-index: -2;
}

.btn-outline-theme:before {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 0%;
height: 100%;
background-color: #657FF1;
transition: all 0.3s;
z-index: -1;
}

.btn-outline-theme:hover {
color: #fff;
}

.btn-outline-theme:hover:before {
width: 100%;
}

.btn-report {
display: inline-block;
padding: 0.10rem .50rem;
color: #fff;
background-color: #78909c;
border: solid 1px #78909c;
text-transform: uppercase;
transition: all 0.3s;
position: relative;
overflow: hidden;
z-index: 1;
border-radius: 5px;
/* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}




/* ------------------------------------------------------ */
.main-header {
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%; 
  display: flex;
  align-items: center;
}

.logo-wrapper {
  width: 240px;
  display: flex;
  align-items: center;
}

.menu-icon {
  margin: 0px 22px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 240px);
}

.welcome-name {
  font-family: 'Circular Std Bold' !important;
  font-style: normal;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.668571px;
  margin-left: 21px;
}

.search-content {
  margin-left: 20px;
}

.search-icon {
  position: absolute;
  top: 15.94px;
  left: 15px;
  z-index: 1;
  color: #bbc0e5;
  width: 18px;
  height: 17px;
}

.search-style {
  padding-left: 40px;
  height: 48px;
  background: #1F253D;
  border: 1px solid #7C80A0;
  border-radius: 8px;
  color: #7C80A0;
  font-family: 'Circular Std Book' !important;
  font-size: 14px;
}

.notification-section {
  margin-right: 78px;
}

.bell-icon {
  width: 18.11px;
  height: 19.63px;
}

.pending-notification-dot {
  width: 8px;
  height: 8px;
  background: #0DED2F;
  border: 1px solid #1E2235;
  content: '';
  position: absolute;
  right: 1px;
  top: -2px;
  border-radius: 50%;
}

.timezone-section {
  margin-right: 5px;
  font-size: 14px;
  color: #7C80A0;
}

.currency-section {
  margin-right: 6px;
}

@media (max-width: 426px) {
  .currency-section {
    display: none;
  }
}

@media (min-width: 426px) {
  .currency-section {
    display: inline-block;
  }
}

@media (max-width: 1140px) {
  .search-style {
    width: 250px;
  }

  .search-content {
    margin-left: 5px;
  }
}

@media (min-width: 1140px) and (max-width: 1260px) {
  .search-style {
    width: 333px;
  }
}

@media (min-width: 1260px) {
  .search-style {
    width: 459px;
  }
}

@media (max-width: 1400px) {
  .welcome-name {
    width: 184px !important;
    font-size: 20px;
  }

  .notification-section {
    margin-right: 15px;
  }
}

@media (min-width: 1400px) {
  .welcome-name {
    width: 234px;
  }
}

@media (max-width: 1023.9px) {
  .welcome-name {
    display: none;
  }

  .search-content {
    display: none;
  }

  .timezone-section {
    display: none;
  }
}

